import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function UxDesign() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner section--gray-bg">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading">UX Design </h1>
            <h2 className="container__sub-heading">
              <span className="--red-color">Transform your brand story</span>{" "}
              through unified omni channel user experiences
            </h2>
            <h6 className="container__content">
              Our UX designers approach every project with{" "}
              <span className="--red-color">
                deep empathy and a product mindset.
              </span>{" "}
              We apply futuristic engagement, best practices and cutting-edge
              tools to{" "}
              <span className="--red-color">
                create groundbreaking and people-centered
              </span>{" "}
              websites, apps, campaign toolkits, and products.
            </h6>
            <a onClick={openModal}>
              <button className="container__button">LET’S TALK</button>
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/solutions/ux-design.png"
              alt="UX Design"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Ux Design Banner"
        page="Web Experiences page"
       
      />
    </section>
  );
}

export default UxDesign;

// import React from "react";

// function UxDesign() {
//   return (
// <section className="section section--banner section--gray-bg">
//   <div className="container">
//     <div className="container__row">
//       <div className="container__col-md-6 --section-large-tb-padding">
//         <h1 className="container__heading">UX Design </h1>
//         <h2 className="container__sub-heading">
//           <span className="--red-color">Transform your brand story</span>{" "}
//           through unified omni channel user experiences
//         </h2>
//         <h6 className="container__content">
//           Our UX designers approach every project with{" "}
//           <span className="--red-color">
//             deep empathy and a product mindset.
//           </span>{" "}
//           We apply futuristic engagement, best practices and cutting-edge
//           tools to{" "}
//           <span className="--red-color">
//             create groundbreaking and people-centered
//           </span>{" "}
//           websites, apps, campaign toolkits, and products.
//         </h6>
//         <button className="container__button">LET’S TALK</button>
//       </div>
//       <div className="container__col-md-6 --flex-center">
//         <img
//           className="container__img --bg-absolute-right"
//           src="../../solutions/solutions/ux-design.png"
//           alt="UX Design"
//         />
//       </div>
//     </div>
//   </div>
//     </section>
//   );
// }

// export default UxDesign;
