import React from "react";

function Approach() {
  return (
    <div className="container__row --flex-center">
      <div className="container__col-xs-12">
        <h2 className="container__heading">Our Approach</h2>
      </div>

      <div className="container__col-xs-12 --flex-center --section-medium-tb-padding">
        <img src="../../solutions/solutions/process.png" alt="Process" />
      </div>
    </div>
  );
}

export default Approach;
