import React from "react";

function ProcessFlow() {
  return (
    <div className="process-flow">
      <div className="container__row --flex-center">
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--1-bg">
          <h2>Business Goals</h2>
          <div>
            One of the most important phases in UX design is building on the
            concept of the product. Our team and stakeholders create consensus
            around what the product will be and how to match users and business
            needs; creating an early mockup of the future product
          </div>
        </div>
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/business-goals.png"
            alt="Business Goals"
          />
        </div>
      </div>

      <div className="container__row --flex-center">
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/research.png"
            alt="Research"
          />
        </div>
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--2-bg">
          <h2>Research</h2>
          <div>
            Good research informs design decisions. A great product experience
            starts with a good understanding of the users. We gather data about
            the target audience and competitors to understand industry standards
            and identify opportunities for the product within its niche.
          </div>
        </div>
      </div>

      <div className="container__row --flex-center">
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--3-bg">
          <h2>Strategy</h2>
          <div>
            In consulting Strategy, is innovation and a differentiator. Our
            digital strategists help you craft highly engaging UX strategy that
            is cognisant of your business goals, industry trends and customer
            engagement data to set your digital experiences miles ahead of your
            competition.{" "}
          </div>
        </div>
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/strategy.png"
            alt="Strategy"
          />
        </div>
      </div>

      <div className="container__row --flex-center">
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/implementation.png"
            alt="Implementation"
          />
        </div>
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--4-bg">
          <h2>Implementation</h2>
          <div>
            A skilled UI development team with passion to bring alive UX designs
            with the same level perfection is the back bone of a digital team.
            Our team of dedicated UI developers combined with rigorous UI
            testing across platforms makes every project just incredible.{" "}
          </div>
        </div>
      </div>

      <div className="container__row --flex-center">
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--5-bg">
          <h2>Analytics</h2>
          <div>
            Web is the language your web experiences use to communicate how they
            are doing. Be it simple analytics setup with Google Analytics or
            complex enterprise implementations like Adobe Analytics, we have the
            expertise to setup right, so that you get maximum insights.{" "}
          </div>
        </div>
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/analytics.png"
            alt="Analytics"
          />
        </div>
      </div>

      <div className="container__row --flex-center">
        <div className="container__col-md-5 container__col-md-offset-1 --flex-center">
          <img
            src="../../solutions/solutions/process/optimization.png"
            alt="Optimization"
          />
        </div>
        <div className="container__col-md-4 container__col-md-offset-2 --section-medium-tb-padding section--6-bg">
          <h2>Optimization</h2>
          <div>
            Continuous improvement is key every high performing experiences. Our
            digital strategy has the mindset and expertise to continuous monitor
            your analytics, customer journey and ROI to surface optimisation
            across UI, performance and personalisation.{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessFlow;
