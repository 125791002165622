import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import OurProjects from "../components/Solutions/OurProjects/OurProjects";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import UxDesign from "../components/Solutions/Ux-Design/Ux-Design";
import OurApproach from "../components/Solutions/OurApproach/OurApproach";
import { Helmet } from "react-helmet";

function solutionsUxDesign() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies - UX Design     </title>
       <meta
         name="description"
         content="Transform your brand story through unified omni channel user experiences."
       ></meta></Helmet>
      <SolutionsHelmet />
      <UxDesign />
      <OurApproach />
      <OurProjects />
      <LearnAboutUs page="ux-design"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default solutionsUxDesign;
