import React from "react";
import Approach from "../Approach/Approach";
import ProcessFlow from "../ProcessFlow/ProcessFlow";

function OurApproach() {
  return (
    <section className="section section--blog --section-normal-tb-padding">
      <div className="container">
        <Approach />
        <ProcessFlow />
      </div>
    </section>
  );
}

export default OurApproach;
